<template>
  <v-container class="roboto-font">
    <v-autocomplete
      style="max-width: 600px; margin: auto"
      v-model="model"
      :items="items"
      :loading="isLoading"
      :search-input.sync="search"
      hide-no-data
      hide-selected
      item-text="Description"
      item-value="Word"
      label="Search Dictionary"
      placeholder="Start typing to Search"
      prepend-icon="mdi-magnify"
      return-object
      v-on:keyup.enter="searchThis()"
    ></v-autocomplete>
    <div v-if="!loading">
      <div v-if="data">
        <v-card
          v-for="(item, index) in data.meaning"
          :key="index"
          class="mx-auto my-12"
          max-width="600"
        >
          <v-card-title>
            <v-row>
              <v-col>
                <span
                  style="color: #007db9"
                  :class="{ 'tanaqui-font': containsKaren(data.word) }"
                  >{{ data.word }}
                </span>
              </v-col>
              <v-col class="text-right">
                <v-btn
                  v-if="user"
                  icon
                  :class="{ favorite: favoritesCompute[index] }"
                  @click="toggleFavorite(index)"
                >
                  <v-icon>mdi-heart</v-icon>
                </v-btn>
                <v-btn
                  v-if="user && user.displayName === item.user"
                  @click="
                    $router.push({ name: 'Edit', params: { word: word } })
                  "
                  icon
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>

          <v-card-text>
            <span class="grey--text"
              >{{ item.type }}
              <v-btn
                v-if="dictionaryApi && item.pronounceSound"
                x-small
                icon
                color="secondary"
                dark
                @click="playAudio(item.pronounceSound)"
              >
                <v-icon>mdi-volume-high</v-icon>
              </v-btn>
              <v-btn
                v-else-if="dictionaryApi && getPhonetics(item) !== ''"
                x-small
                icon
                color="secondary"
                dark
                @click="playSound(item)"
              >
                <v-icon>mdi-volume-high</v-icon>
              </v-btn>

              <span v-if="dictionaryApi"
                ><span v-if="item.phonetic">{{ item.phonetic }}</span
                ><span v-else>{{ getPhonetics(item) }}</span></span
              >
            </span>

            <div v-show="item.context" class="my-3">
              <div>context</div>
              <div :class="{ 'tanaqui-font': containsKaren(item.context) }">
                {{ item.context }}
              </div>
            </div>

            <div v-show="item.translation" class="my-3">
              <div>translation</div>
              <div :class="{ 'tanaqui-font': containsKaren(item.translation) }">
                {{ item.translation }}
              </div>
            </div>
            <div v-show="item.definition" class="my-3">
              <div>definition</div>
              <div
                style="white-space: pre-line"
                :class="{ 'tanaqui-font': containsKaren(item.definition) }"
              >
                {{ item.definition }}
              </div>
            </div>
            <div v-show="item.example" class="my-3">
              <div>example</div>
              <div
                style="white-space: pre-line"
                :class="{ 'tanaqui-font': containsKaren(item.example) }"
              >
                {{ item.example }}
              </div>
            </div>
            <div class="font-italic small" style="font-size: 0.75rem">
              <span v-if="item.updated"
                >updated by {{ item.updatedBy }} on
                {{ item.updated.toDate() }}</span
              ><span v-else
                >created by {{ item.user }} on {{ item.created.toDate() }}</span
              >
            </div>
          </v-card-text>

          <!-- <v-divider class="mx-4"></v-divider>

          <v-card-actions>
            <v-btn-toggle borderless>
              <v-btn value="left">
                <span>{{ item.likes }}</span>

                <v-icon right> mdi-thumb-up </v-icon>
              </v-btn>

              <v-btn value="justify">
                <span>{{ item.dislikes }}</span>

                <v-icon right> mdi-thumb-down </v-icon>
              </v-btn>
            </v-btn-toggle>
          </v-card-actions> -->
        </v-card>
      </div>

      <div class="text-center" v-else>
        <v-icon> mdi-cancel </v-icon> <span>no definition found</span><br />
        <router-link :to="{ name: 'Create' }"
          >do you want to add the word?</router-link
        >
      </div>
    </div>
    <div v-else>
      <v-skeleton-loader
        class="pa-3"
        style="max-width: 600px; margin: auto"
        type="card"
      ></v-skeleton-loader>
    </div>
  </v-container>
</template>

<script>
import { getFirestore } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import axios from "axios";
import {
  doc,
  getDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
export default {
  async mounted() {
    const auth = getAuth();
    const db = getFirestore();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        this.user = user;
        console.log(uid);
        const userDataRef = doc(db, "users", uid);
        const userDataSnap = await getDoc(userDataRef);

        if (userDataSnap.exists()) {
          console.log("User data:", userDataSnap.data());
          this.userData = userDataSnap.data();
        }
      }
    });

    const docRef = doc(db, "word", this.$route.params.word);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());

      this.data = docSnap.data();
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }

    axios
      .get(
        "https://api.dictionaryapi.dev/api/v2/entries/en/" +
          this.$route.params.word
      )
      .then((response) => {
        console.log(response.data);
        this.dictionaryApi = response.data;
      });

    const docRef1 = doc(db, "data", "wordList");
    const docSnap1 = await getDoc(docRef1);

    if (docSnap1.exists()) {
      console.log("Document data:", docSnap1.data());
      this.count = docSnap1.data().words.length;
      this.entries = docSnap1.data().words.sort();
      this.isLoading = false;
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }

    this.loading = false;
  },
  methods: {
    isFavorite(id) {
      console.log("isFavorite");
      console.log(id);
      let found = false;

      this.userData.favorites.forEach((elem) => {
        if (elem.word === this.$route.params.word && elem.id == id) {
          found = true;
        }
      });

      return found;
    },
    playAudio(url) {
      new Audio(url).play();
    },
    playSound(item) {
      console.log(item);
      this.dictionaryApi.forEach((m) => {
        m.phonetics.forEach((p) => {
          this.playAudio(p.audio);
        });
      });
    },
    getPhonetics(item) {
      let str = "";
      console.log(item);
      console.log(this.dictionaryApi);
      this.dictionaryApi.forEach((m) => {
        if (m.phonetic) {
          str = m.phonetic;
        }
      });

      console.log("str is '" + str + "'");
      return str;
    },
    containsKaren(str) {
      let found = false;
      let karenAphabet = [
        "က",
        "ခ",
        "ဂ",
        "ဃ",
        "င",
        "စ",
        "ဆ",
        "ၡ",
        "ည",
        "တ",
        "ထ",
        "ဒ",
        "န",
        "ပ",
        "ဖ",
        "ဘ",
        "မ",
        "ယ",
        "ရ",
        "လ",
        "ဝ",
        "သ",
        "ဟ",
        "အ",
        "ဧ",
      ];
      for (var i = 0; i < str.length; i++) {
        karenAphabet.forEach((alphabet) => {
          // console.log(alphabet);
          // console.log(str.charAt(i));
          if (alphabet === str.charAt(i)) {
            console.log(true);
            found = true;
          }
        });
      }

      return found;
    },
    async toggleFavorite(index) {
      const db = getFirestore();
      const myWords = doc(db, "users", this.user.uid);
      if (this.isFavorite(index)) {
        let arr = this.userData.favorites;
        this.userData.favorites = arr.filter((e) => {
          return e.word !== this.word || e.id !== index;
        });
        // Atomically remove a region from the "regions" array field.
        await updateDoc(myWords, {
          favorites: arrayRemove({ word: this.word, id: index }),
        });
      } else {
        this.userData.favorites.push({ word: this.word, id: index });
        // Atomically add a new region to the "regions" array field.
        await updateDoc(myWords, {
          favorites: arrayUnion({ word: this.word, id: index }),
        });
      }
    },
    async searchThis() {
      if (this.search.length > 0) {
        console.log(this.search);
        this.$router.replace({
          params: { word: this.search.toLowerCase().trim() },
        });
        const db = getFirestore();
        const docRef = doc(db, "word", this.$route.params.word);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          console.log("Document data:", docSnap.data());

          this.data = docSnap.data();
          this.word = this.data.word;
          this.reload();
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
          this.data = null;
        }
      }
    },
    async searchModel() {
      if (this.model.length > 0) {
        console.log(this.model);
        this.$router.replace({
          params: { word: this.model.toLowerCase().trim() },
        });
        const db = getFirestore();
        const docRef = doc(db, "word", this.$route.params.word);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          console.log("Document data:", docSnap.data());

          this.data = docSnap.data();
          this.word = this.data.word;
          this.reload();
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
          this.data = null;
        }
      }
    },
    async reload() {
      axios
        .get(
          "https://api.dictionaryapi.dev/api/v2/entries/en/" +
            this.$route.params.word
        )
        .then((response) => {
          console.log("catching new data");
          console.log(response.data);
          this.dictionaryApi = response.data;
        });
    },
  },
  data() {
    return {
      data: null,
      word: this.$route.params.word,
      user: null,
      userData: null,
      dictionaryApi: null,
      loading: true,
      descriptionLimit: 60,
      entries: [],
      isLoading: false,
      model: null,
      search: null,
    };
  },
  computed: {
    favoritesCompute() {
      if (!this.data || !this.userData) return;
      let temp = [];
      this.data.meaning.forEach(() => {
        temp.push(false);
      });
      this.userData.favorites.forEach((elem) => {
        if (elem.word === this.word) {
          temp[elem.id] = true;
        }
      });
      return temp;
    },
    fields() {
      console.log("field");
      if (!this.model) return [];

      return Object.keys(this.model).map((key) => {
        return {
          key,
          value: this.model[key] || "n/a",
        };
      });
    },
    items() {
      return this.entries;
    },
  },
  watch: {
    // whenever question changes, this function will run
    model: function () {
      console.log("run");
      this.searchModel();
    },
  },
};
</script>

<style scoped>
.favorite > .v-btn__content i {
  color: rgb(243, 0, 0);
}
</style>
